.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border: 2px solid #000;
    background: #fff;
    color: #000;
    padding: 40px;
}

.modalBox a {
    font-weight: 600;
}

.modalCloseButton {
    position: absolute;
    top: 7px;
    right: 7px;
}

