.container {
    background: white;
    color: black;
}

.titles {
    text-align: center;
    margin-bottom: 50px;
}

.title {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
}

.subtitle {
    font-size: 24px;
}

.card {
    flex: 1;
    text-align: center;
    min-height: 415px;
}

.accordion {
   margin-bottom: 20px;
}

.accordionsummary {
    padding: 20px 30px;
}

.accordionsummary p {
    font-weight: 700;
}

.accordiondetails {
    padding: 0 30px 20px 30px;
}

.accordiondetails a {
    font-weight: 500;
}

