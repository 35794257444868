
.title {
    text-transform: uppercase;
    font-size: 58px;
    margin-bottom: 12px;
    font-weight: 700;
    -webkit-text-stroke: 2px white;
    color: white;
    letter-spacing: 4px;
    -webkit-text-fill-color: transparent;
}

@media (max-width:600px) {
    .title {
        font-size: 40px;
    }
}
