.container {
    background: white;
    width: 100%;
}

.containerImage {
    background: url('../public/images/whitelist/whitelistbckg.jpg');
    background-position: center;
    background-size: cover;
    mask-image: url('../public/images/whitelist/bgmask.svg');
    mask-size: cover;
    mask-repeat: no-repeat;
    mask-position: center bottom;
}

.titles {
    text-align: center;
    margin-top: 8%;
    margin-bottom: 50px;
}

.subtext {
    font-size: 18px;
    max-width: 330px;
    font-weight: 600;
}

.card {
    background: #1E1D20;
    color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    min-height: 395px;
    min-width: 337px;
}

.card h2 {
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.cardbox {
    max-width: 257px;
    min-height: 175px;
    margin: 10px 0;
}

.nowallet {
    margin: 5% 0;
}

.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border: 2px solid #000;
    background: #fff;
    color: #000;
    padding: 40px;
}

.modalBox a {
    font-weight: 600;
}

.modalCloseButton {
    position: absolute;
    top: 7px;
    right: 7px;
}

.downArrow {
    cursor: pointer;
}
